<template>
  <div class="'GallRomBibl'">
    <PageTitle title="GallRomBibl" />
    <b-container fluid="xxl">
      <b-row>
        <b-col cols="8">
          <p><b><i>Répertoires bibliographiques de textes galloromans médiévaux</i></b><br/><br/>

          Version électronique<br/>
          dirigée par Martin Glessgen<br/>
          en partenariat avec Caterina Menichetti et Frankwalt Möhren<br/>
          Version préparatoire (2024)
          </p>
          <p>Les <i>Répertoires bibliographiques de textes galloromans médiévaux</i> (GallRomBibl) comportent la bibliographie philologique du <i>Dictionnaire étymologique de l'ancien français</i> (DEAFBibl), celle du <i>Dictionnaire étymologique d'ancien occitan</i> (DEAOBibl) – conçue de manière amalgame avec le <i>Répertoire critique des manuscrits littéraires en ancien occitan</i> (RépCrit, RC) de Caterina Menichetti – et la bibliographie du <i>Dictionnaire étymologique d'ancien gascon</i> (DEAGBibl). S'ajoute le répertoire bibliographique des <i>Documents linguistiques galloromans</i> (DocLingBibl).</p>
          <p>La structure de chacun de ces répertoires – qui sont interrogeables individuellement ou de manière conjointe – repose sur la distinction systématique des textes, des manuscrits et des éditions, soit les trois entités essentielles pour appréhender le patrimoine textuel médiéval. Le texte est le véritable objet de l'analyse linguistique et littéraire, mais c'est une entité abstraite. La concrétisation du texte médiéval est fournie par le(s) manuscrit(s), tandis que sa représentation utilisable pour une analyse (hors philologie matérielle) est l'édition moderne.</p>
          <p>Le binôme DEAOBibl/RépCrit de même que la DEAGBibl ont été conçus d'emblée dans cette optique, alors que la DEAFBibl de Frankwalt Möhren – dont la cinquième édition sous format papier a été publiée en 2021 – a été structurellement transformée dans le cadre de la GallRomBibl.</p>
          <p>La mise en commun des différents répertoires philologiques donne un aperçu de l'essentiel du patrimoine textuel non documentaire de la Galloromania. Les textes documentaires, innombrables et pour la plupart inédits, ne sont représentés que partiellement, à travers les corpus des DocLing et les éditions utilisées dans les dictionnaires philologiques médiévistes.</p>
          <router-link :to="{ name: 'SigleEntities' }" class="btn btn-secondary btn-legam mt-4">
            Explore GallRomBibl
          </router-link>
          <br>
          <a href="/pdfs/further-information.pdf" class="btn btn-secondary btn-legam mt-4">
            <svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="file pdf fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi-file-pdf-fill mx-auto b-icon bi" data-v-41be6633=""><g data-v-41be6633=""><path d="M5.523 10.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.035 21.035 0 0 0 .5-1.05 11.96 11.96 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.888 3.888 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 4.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z"></path><path fill-rule="evenodd" d="M4 0h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm.165 11.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.64 11.64 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.707 19.707 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z"></path></g></svg>
            {{ $t('FurtherInformation') }}
          </a>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style scoped>
</style>

<script>
  import PageTitle from '@/components/PageTitle.vue'

  export default {
    name: 'GallRomBibl',
    data() {
      return {

      }
    },
    components: {
      PageTitle,
    },
    computed: {
    },
    mounted() {
    },
    watch: {
    },
    methods: {
    }
  }
</script>
